import React from 'react';
import { FaYoutube, FaInstagram } from 'react-icons/fa';
import './Footer.css';

function Footer(){
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">
          &copy; 2024 <a href="https://www.youtube.com/channel/UCs4oAFxhxxVhtW-_ApKtkLQ" target="_blank" rel="noopener noreferrer"> 하뉴데이 </a> All rights reserved.
        </p>
        
        <div className="social-icons">
          <a href="https://www.youtube.com/@hanewlog" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaYoutube />
          </a>
          <a href="https://www.instagram.com/hanuzoo?igsh=anY3dnBrd3VrMzBt&utm_source=qr" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;